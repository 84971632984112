@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@yaireo/tagify/dist/tagify.css';
@import './base.scss';
@import './calendar.scss';
@import './actiontext.scss';
@import './learning_centre.scss';
@import './utilities.css';
@import "dropzone/dist/min/dropzone.min.css";
@import "dropzone/dist/min/basic.min.css";
@import "./popper.css";

.loading * {
  cursor: wait !important;
}

a {
  @apply cursor-pointer;
}

@media (min-width: 640px) {
  .input {
    max-width: 100% !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}

.label {
  @apply block text-sm font-bold leading-5 text-gray-700;
}

.label-white {
  @apply block text-white;
}

.label-sm {
  @apply block text-sm font-medium leading-5 text-gray-700;
}

.input {
  @apply max-w-lg block w-full shadow-sm focus:ring-brand-dark focus:border-brand-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.pagy-nav,
.pagy-nav-js,
.pagy-combo-nav-js {
  @apply inline-flex shadow-md;
}
.pagy-nav.pagination,
.pagy-nav-js.pagination,
.pagy-combo-nav-js.pagination {
  @apply border border-gray-400 rounded-sm mt-4;
}
.pagy-nav .page,
.pagy-nav-js .page,
.pagy-combo-nav-js .page,
.pagy-combo-nav-js .pagy-combo-input {
  @apply text-gray-700 border-r border-gray-600 px-3 py-2 text-sm leading-tight font-medium;
}
.pagy-nav .page:hover,
.pagy-nav-js .page:hover {
  @apply text-gray-900;
}
.pagy-nav .disabled,
.pagy-nav-js .disabled,
.pagy-combo-nav-js .disabled {
  @apply cursor-not-allowed;
}
.pagy-nav .active,
.pagy-nav-js .active {
  @apply text-blue-500;
}
.pagy-nav .prev,
.pagy-nav-js .prev,
.pagy-combo-nav-js .prev {
  @apply text-gray-900;
}
.pagy-nav .next,
.pagy-nav-js .next,
.pagy-combo-nav-js .next {
  @apply text-gray-900 border-r border-transparent;
}

.dropzone {
  @apply border-2 rounded-lg border-brand-light border-dashed;

  &.dz-drag-hover {
    @apply border-2 rounded-lg border-brand-light border-dashed;

    .dz-message {
      opacity: 0.9;
    }
  }
}

.dropzone.staff-dropzone {
  min-height: 80px;

  .dz-message {
    margin: 0.5rem;
  }
}

a.chosen-option {
  @apply shadow-xl bg-brand-dark;

  h4 {
    @apply text-white;
  }
}

.static-content p {
  @apply leading-6 text-gray-700 mb-4;
}

.client-calendar {
  .fc-list-event-time {
    display: none;
  }
}

.th {
  @apply px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}

td.td {
  @apply px-6 py-3 leading-5 text-gray-700;
}

[x-cloak] {
  display: none !important;
}

.learning-step-editor  {
  .trix-content {
    min-height: 320px;
    background-color: #FFF !important;
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid-sizer, .grid-item {
  width: 33%;
}

.gutter-sizer {
  width: 0.5%;
}

.grid-item--width2 {
  width: 49.75%;
}

@media screen and (max-width: 840px) {
  .gutter-sizer {
    width: 1%;
  }

  .grid-sizer, .grid-item {
    width: 49%;
  }
}
@media screen and (max-width: 620px) {
  .gutter-sizer {
    width: 0.5%;
  }

  .grid-sizer, .grid-item {
    width: 99.5%;
  }
}

a.link {
  @apply text-blue-500 font-medium hover:text-blue-600;
}

.form-select {
  @apply block
    w-full
    px-4
    pr-6
    py-2
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding bg-no-repeat
    border border-solid border-gray-300
    rounded-lg
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-brand-dark focus:outline-none;
}

.file-input {
  @apply block w-full px-3 py-1 text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand-dark focus:outline-none;
}

table.table {
  @apply min-w-full divide-y divide-gray-200;
}

th.th {
  @apply px-6 py-3 bg-gray-50 text-left text-xs leading-4;
}

.dropzone.client-document-dropzone {
  min-height: auto;
  border: 0;
  padding: 2px;
  width: 100%;

  .dz-message {
    padding: 4px;
    margin: 0;
  }
}