/*TOOLTIP START*/
    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
        cursor: default;
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        width: auto;
        min-width: 300px;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 8px;
        border-radius: 6px;

        /* Position the tooltip text */
        position: absolute;
        z-index: 10;
        bottom: 125%;
        left: 50%;

        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }