h2.h2 {
  @apply text-lg font-medium leading-6 text-gray-900;
}

.text-secondary {
  @apply text-sm text-gray-500;
}

.input-field {
  @apply input sm:text-sm sm:leading-5;
}

.btn {
  @apply inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 cursor-pointer font-semibold rounded-md text-white bg-brand-dark focus:outline-none transition duration-150 ease-in-out mb-2;
}