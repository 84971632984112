.calendar-event {
  .calendar-event__hidden {
    display: none;
  }

  &:hover {
    max-height: none;
    overflow: visible;
    min-width: 258px;

    .calendar-event__hidden {
      display: flex;
      flex-direction: column;
    }
  }
}

.fc-time-grid:hover .fc-event:hover, .fc-time-grid:hover .fc-bgevent:hover {
  z-index: 100 !important;
}

.fc .fc-daygrid-event:hover {
  z-index: 100 !important;
}