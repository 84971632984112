










































































































.drop-zone {
  border: 3px solid #eee;
  min-height: 50px;
}
.drop-zone.dragging {
  border: 3px dashed #444;
  position: relative;
}
.drop-zone.dragging:before {
  content: "Lassen Sie die Datei hier fallen um den Upload zu beginnen";
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  font-style: italic;
  color: #555;
}
input[type=file][data-direct-upload-url][disabled] {
  display: none;
}
