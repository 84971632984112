.learning-nav {
  @apply overflow-y-auto py-4 px-3 bg-gray-50 rounded;
}

.learning-link-group {

}

.learning-topic {
  @apply bg-brand-light font-normal text-white rounded-lg px-2;
}

a.learning-link {
  @apply font-semibold p-2 text-base;
}

.learning-topic.active {
  @apply bg-brand-dark;
}

a.learning-sub-link {
  @apply ml-4 flex font-semibold p-2 text-base bg-brand-light font-normal text-white rounded-lg;
}

a.learning-sub-link.active {
  @apply bg-brand-dark;
}

.learning-topic.active:hover {
  @apply bg-brand-light;
}

.learning-topic:hover {
  @apply bg-brand-dark;
}

.learning-sub-link.active:hover {
  @apply bg-brand-light;
}

.learning-sub-link:hover {
  @apply bg-brand-dark;
}

.learning-content h1.title {
  @apply text-4xl font-black text-gray-800;
}

.learning-content h1 {
  @apply text-2xl font-bold text-gray-800;
}

.learning-content p {
  @apply leading-relaxed my-4;
}
.learning-footer {
  flex-direction: row-reverse;
}
.hide-link {
  opacity: 0;
}
