@import "trix/dist/trix";

trix-editor ul li, .trix-content ul li {
  list-style: circle;
  margin-left: 2em;
}

trix-editor ol li, .trix-content ol li {
  list-style: decimal;
  margin-left: 2em;
}

trix-editor a {
  @apply text-blue-500 font-medium;
}

trix-editor h1 {
  @apply text-2xl text-gray-800 font-semibold;
}

#send-message-form {
  trix-toolbar .trix-button-group.trix-button-group--file-tools {
    display: none;
  }

  trix-toolbar .trix-button.trix-button--icon.trix-button--icon-code {
    display: none;
  }
}

trix-toolbar .trix-button-group button[data-trix-attribute=red] {
  color: red;
}

.trix-content {
  word-break: break-word;
  white-space: normal;
}